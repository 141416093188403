import { HashLink as Link } from 'react-router-hash-link';
import './TokensList.css';

const TokensList = ({ListItems, ListItemAlt, ListWithLink, collectionId, withTitle, collectionUrl, TokensListToOpensea}) => {
    const listContent = ListItems.map((ListItem) => {
        if(ListWithLink){
            if(TokensListToOpensea){
                return (
                    // Collection 2
                    <div key={ListItem} className="tokens_list_item">
                        <a href={"https://opensea.io/assets/ethereum/0x8a7baC741D1F54992a5F26DAA90C0c5C3f74a941/" + ListItem} title={ListItemAlt + ' ' + ListItem} target="_blank" rel="noreferrer nofollow noopener">
                            <img src={"https://api.hypercube.art/images/collection/"+collectionId+"/small/"+ListItem+".jpg"} title={ListItemAlt + ' ' + ListItem} alt={ListItemAlt + ' ' + ListItem} />
                            <div className="tokens_list_item_title">{ListItemAlt + ' '+ ListItem}</div>
                        </a>
                    </div>
                )
            } else {
                return (
                    <div key={ListItem} className="tokens_list_item">
                        <Link to={'/' + collectionUrl + ListItem + '#top'} smooth key={ListItem}  title={ListItemAlt + ' ' + ListItem}>
                            <img src={"https://api.hypercube.art/images/collection/"+collectionId+"/small/"+ListItem+".jpg"} title={ListItemAlt + ' ' + ListItem} alt={ListItemAlt + ' ' + ListItem} />
                            {(withTitle)?(<><div className="tokens_list_item_title">{ListItemAlt + ' '+ ListItem}</div></>):""}
                        </Link>
                    </div>
                )
            }
        } else {
            return (
                <div key={ListItem} className="tokens_list_item">
                    <img src={"https://api.hypercube.art/images/collection/"+collectionId+"/small/"+ListItem+".jpg"} title={ListItemAlt + ' ' + ListItem} alt={ListItemAlt + ' ' + ListItem} />
                </div>
            )
        }
    });
    
    return (
        <section>
            <div className="tokens_list">
                {listContent}
            </div> 
        </section>
    )
}

export default TokensList