import { useEffect } from 'react';
import { Helmet } from "react-helmet-async";

import DropsList from '../common/DropsList';
import TeamList from '../common/TeamList';
import NewArtists from '../common/NewArtists';

import './Index.css';

const Index = ({AppName, AppUrl, setCurrentPage}) => {

    useEffect(() => {
		setCurrentPage('index');
	}, [setCurrentPage]);

    return (
        <>
            <Helmet>
                <title>{AppName + ' - Art and Code'}</title>
                <meta name="description" content={AppName + ' - Art and Code'} />
                <meta name="keywords" content={AppName + ' - Art and Code'} />
                <meta property="og:title" content={AppName + ' - Art and Code'} />
                <meta property="og:url" content={AppUrl} />
                <meta property="og:description" content={AppName + ' - Art and Code'} />
                <meta property="og:image" content={AppUrl + '/share.jpg'} />
                <link rel="canonical" href={AppUrl} />
            </Helmet>

            <section className="index-container">
                <h1 className="index-large-title">HYPERCUBE IS A PLATFORM FOR ART AND CODE</h1>
                <p className="index-subtitle">WE WORK WITH A CURATED COLLECTION OF ARTISTS CREATING ART DESIGNED FOR THE BLOCKCHAIN</p>
                <DropsList />
                <h1 className="index-large-title">WE ARE AT THE BEGINNING OF A NEW GENRE OF ART.</h1>
                <ul className="index-features-list">
                    <li>WHERE ART CAN BE GENERATED BY AN ALGORITHM AT THE MOMENT OF PURCHASE, SURPRISING BOTH THE BUYER AND THE ARTIST</li>

                    <li>WHERE ART CAN EVOLVE OVER TIME, OR MUTATE WHEN SOLD</li>

                    <li>WHERE TWO OR MORE ART PIECES CAN BE COMBINED TO PRODUCE SOMETHING ENTIRELY NEW...</li>

                    <li>THIS IS <b>BLOCKCHAIN-NATIVE ART</b>, AND HYPERCUBE IS HERE TO SHOWCASE THE BEST NEW ART IN THIS GENRE.</li>
                </ul>
                <div className="index-description">
                    <h3>EXPERT CURATION</h3>
                    <p>The collections featured on HyperCube are curated by experts from both the traditional and digital art worlds. HyperCube represents high quality art that will stand the test of time.</p>

                    <h3>COMMUNITY OWNED</h3>
                    <p>HyperCube is owned by the community. We are launching a governance token that allows holders to vote on how to spend the profits that the platform generates, and to nominate expert curators to curate new collections.</p>

                    <h3>ARTISTS + CODERS</h3>
                    <p>A new genre is being born and we want to help people participate.</p>
                    
                    <p><b>Artist and coder matching</b>. Many artists with amazing creative ideas don't yet have the coding/mathematical skills to create them. Many coders with these skills want participate but don't have creative inspiration for high quality art. HyperCube creates collaborations between artists and coders who share the proceeds from successful drops. <a href="https://docs.google.com/forms/d/e/1FAIpQLSdu72IlSfec9GEZ7aSeILVAWwWQiKVGQ5X3TnO2lY0Wocc0rg/viewform?usp=send_form" title="Apply" target="_blank" rel="noreferrer nofollow noopener">Apply here</a>.</p>

                    <p><b>NFT university</b>. We have a free course teaching artists how to navigate the new world of NFTs, how to create a successful drop, and all about blockchain-native art.</p>

                    <a href="https://whitepaper.hypercube.art/" className="index-description-read-more" title="Whitepaper" target="_blank" rel="noreferrer nofollow noopener">READ MORE IN OUR WHITEPAPER</a>
                </div>
                <h2>👀 OUR TEAM IS DOXXED</h2>
                <p>The HyperCube team has deep experience in art, technology and building multi-million dollar companies.</p>
                <TeamList />
                <NewArtists />
            </section>
        </>
    )
}

export default Index