import { useState, useEffect } from 'react';
import { Helmet } from "react-helmet-async";

// Components
import ConnectPage from '../common/ConnectPage';

import './Mint.css';

const Mint1 = ({ AppName, AppUrl, setCurrentPage, LinkTw, LinkDs, AppSalesLimit, web3Connect, web3, isProvider, userAddress, provider, salesLaunched, CollectionContract, chainID }) => {

    useEffect(() => {
		setCurrentPage('mint1');
	}, [setCurrentPage]);

    const [tokenPrice, setTokenPrice] = useState(250000000000000000);
    // const [tokenAmount, setTokenAmount] = useState(1);
    const [tokenAmount] = useState(1);
    // const [maxAmount, setMaxAmount] = useState(1);
    const [totalSupply, setTotalSupply] = useState(0);
    const [transactionState, setTransactionState] = useState(0);
    const [minted, setMinted] = useState(false);
    const rate = 1000000000000000000;
    
    const [userBalance, setUserBalance] = useState(0);
    const [gasPrice, setGasPrice] = useState(30);

    useEffect(() => {
        getTotalSupply();
        getTokenPrice();
        getWalletBalance();
        getRightBalance();
        getGasPrice();
    });

    const getTotalSupply = async (e) => {
        if(isProvider){
            const resultTotalSupply = await CollectionContract.methods.totalSupply().call();
            if(resultTotalSupply){
                setTotalSupply(resultTotalSupply);
            }
        }
    }
    
    const getTokenPrice = async (e) => {
        if(isProvider){
            const resultTokenPrice = await CollectionContract.methods.getMintPrice().call();
            if(resultTokenPrice){
                // console.log(resultTokenPrice);
                setTokenPrice(resultTokenPrice);
            }
        }
    }

    const getWalletBalance = async (e) => {
        if(web3){
            if(isProvider){
                if(userAddress !== '0'){
                    const resultBalance = await web3.eth.getBalance(userAddress);
                    if(resultBalance){
                        setUserBalance(resultBalance);
                    }
                }
            }
        }
    }

    const getRightBalance = async (e) => {
        if(transactionState === 1 || transactionState === 2 || transactionState === 4){
        } else {  
            if(tokenPrice/rate <= userBalance/rate){
                setTransactionState(0);
            } else {
                setTransactionState(3);
            }
        }
    }

    const getGasPrice = () => {
        fetch('https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=83NNEWF158AJ695GKEEA7HSKNGNZIGQHM6')
			  .then((response) => {
			    return response.json();
			  })
			  .then((data) => {
			    setGasPrice(data.result.ProposeGasPrice);
			});
    }

    
    const handleSubmit = (evt) => {
        evt.preventDefault();
        if(transactionState === 0 || transactionState === 1){
            orderToken();
        }
    }
    
    const orderToken = async(e) => {
        setMinted(false);
        setTransactionState(0);
        if(isProvider){
            if(totalSupply < AppSalesLimit){
                if(userAddress !== '0'){
                    if(tokenAmount > 0){
                        if(tokenPrice > 0){
                            setTransactionState(4);
                            
                            await CollectionContract.methods.purchaseTo(userAddress).send({ from: userAddress, value: tokenPrice})
                            .on('transactionHash', function(hash){
                                // console.log('transactionHash');
                                setTransactionState(2);
                            })
                            .on("error", function(error) {
                                // console.log('error');
                                // console.log(error.message);
                                setTransactionState(1);
                            })
                            .on("receipt", function(receipt) {
                                // console.log('Mint done!');                                    
                                setMinted(true);
                                setTransactionState(0);
                            });
                        }
                    }
                }
            }
        }
    }
    
    return (
        <>
            <Helmet>
                <title>{'Minting Window to the soul - ' + AppName}</title>
                <meta name="description" content={'Minting Window to the soul - ' + AppName} />
                <meta name="keywords" content='Minting Window to the soul' />
                <meta property="og:title" content={'Minting Window to the soul - ' + AppName} />
                <meta property="og:url" content={AppUrl+'/third-secret-drop'} />
                <meta property="og:description" content={'Minting Window to the soul - ' + AppName} />
                <meta property="og:image" content={AppUrl + '/share.jpg'} />
                <link rel="canonical" href={AppUrl+'/third-secret-drop'} />
            </Helmet>

            <div className="page-text" >
                <h1>Secret Drop</h1>

                    {(salesLaunched)?(<>
                        {(!isProvider)?(
                            <div className="whitelist-status-container-connect">
                                <ConnectPage web3Connect={web3Connect} />
                            </div>
                        ):(
                            <>
                                {(totalSupply < AppSalesLimit)?(<>
                                    <div className="icb-container">
                                        <div className="token-minted-counter">Minted: <span>{totalSupply} / {AppSalesLimit}</span></div>   
                                            {(chainID !== 1)?(
                                                <>
                                                    <div className="metamask-chain-msg metamask-msg-error big-error">You should switch to Ethereum MainNet in your wallet!</div>
                                                </>) : (
                                                <>
                                                    <form onSubmit={handleSubmit}>
                                                        {(transactionState === 2 || transactionState === 3 || transactionState === 4)?(<input type="submit" value="Mint" className="icb-container-button icb-container-button-disable"/>) : (<input type="submit"  value="Buy" className="icb-container-button" />)}
                                                    </form>

                                                    {(transactionState === 1) ? (<div className="metamask-msg-error">User denied transaction signature</div>) : ""}
                                                    {(transactionState === 2) ? (<div className="metamask-msg-success">Transaction has been successfully sent to the blockchain.<br />{tokenAmount} tokens gonna appear in your wallet soon!</div>) : ""}
                                                    {(transactionState === 3) ? (<div className="metamask-msg-error">You don't have enough Eth!</div>) : ""}
                                                    {(transactionState === 4) ? (<div className="metamask-msg-success">You need to CONFIRM the transaction in your wallet!</div>) : ""}

                                                    {(minted) ? (<div className="metamask-msg-success">Successfully minted!</div>) : ""}

                                                    <div className="icb-container-buy-total">
                                                        Price: <span>{(tokenPrice/rate).toFixed(2)} ETH</span>
                                                    </div>

                                                    <div>
                                                        You have:  {((tokenPrice/rate) > userBalance/rate)?(<span className="redBalance">{Math.floor(userBalance/rate * 1000) / 1000} ETH</span>):(<span>{Math.floor(userBalance/rate * 1000) / 1000} ETH</span>)}
                                                    </div>

                                                    <div>
                                                        Current Gas Price: {(gasPrice < 140) ? (<span className="greenGas">{gasPrice} gwei</span>):((gasPrice > 250)? (<span className="redGas">{gasPrice} gwei</span>) : (<span>{gasPrice} gwei</span>) )}
                                                    </div>
                                                </>)
                                                }

                                                {(userAddress !== '0') ? (<div className="icb-container-buy-address">Your address: {userAddress}</div>) : "" }

                                                <div>
                                                    If you need any help with minting please contact us:<br /><a href={LinkDs} title="Discord" target="_blank" rel="noreferrer nofollow noopener">Discord</a>&nbsp; <a href={LinkTw} title="Twitter" target="_blank" rel="noreferrer nofollow noopener">Twitter</a>
                                                </div>                          
                                    </div>
                                </>):(<>
                                    <h2>Sold out</h2>
                                </>)}
                            </>
                        )}
                    </>):(<>
                        <h2>Minting has not started yet!</h2>
                    </>)}
            </div>
        </>
    )
}

export default Mint1
