import './ConnectPage.css';

const ConnectPage = ({web3Connect, web3ConnectTorus}) => {
    return (
        <div className="connect-component-container">
            <h2>You need to connect your wallet</h2>
            <div onClick={() => web3Connect()} className="connect-button">Connect wallet</div>
            
            {(web3ConnectTorus)?(<>
                <h1>OR</h1>
                <h2>You can create a wallet using your email via <a href="https://toruswallet.io/" title="Tor.us Wallet" target="_blank" rel="noreferrer nofollow noopener" className="torus-link">Tor.us</a></h2>
                <div onClick={() => web3ConnectTorus()} className="connect-button">Create wallet</div>

                <p className="connect-component-torus-label">Return to this page after creating a wallet and click "Connect wallet" button.<br />You will be able to pay for your purchase with a credit card.</p>
            </>):("")}
        </div> 
    )
}

export default ConnectPage