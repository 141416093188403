import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from "react-helmet-async";
import NoMatch from './NoMatch';
import './Token.css';

const Token1 = ({AppName, AppUrl, setCurrentPage, SystemContract, isSystemProvider, systemTotalSupply}) => {
    let { id } = useParams();
    let int_id = parseInt(id);
    const fetch_url = 'https://api.hypercube.art/json/1/' + int_id;

    //For fetch
    // const [error, setError] = useState(null);
    // const [isLoaded, setIsLoaded] = useState(false);
    const [tokenTraits, setTokenTraits] = useState([]);

    // For System
    const [tokenOwner, setTokenOwner] = useState(0);

    useEffect(() => {
		setCurrentPage('token-page-1');
	}, [setCurrentPage]);

    useEffect(() => {
        fetch(fetch_url)
          .then(res => res.json())
          .then(
            (result) => {
                // setIsLoaded(true);
                setTokenTraits(result.attributes);
            },
            (error) => {
                console.log('Error');
                // setError(error);
            }
          )
    },[int_id]);

    useEffect(() => {
        getTokenOwner();
    });
    
    const getTokenOwner = async (e) => {
        if(int_id > 0 && int_id <= systemTotalSupply){
            if(isSystemProvider){
                const resultSystemTokenOwner= await SystemContract.methods.ownerOf(int_id).call();
                if(resultSystemTokenOwner){
                    setTokenOwner(resultSystemTokenOwner);
                }
            }
        }
    }

    if(int_id > 0){
        let traitsList = '';

        if(tokenTraits){
            if(tokenTraits.length !== 0){
                traitsList = tokenTraits.map((tokenTrait) =>
                <>
                    <div className="token-page-traits-item" key={tokenTrait.trait_type}>
                        {tokenTrait.trait_type}: 
                        {(tokenTrait.trait_type === 'baseAnimation' || tokenTrait.trait_type === 'fiberAnimation')?(
                        <><span>{tokenTrait.value.substr(0, 5)}</span></>
                        ):(
                        <><span>{tokenTrait.value.trim()}</span></>
                        )}
                    </div>
                </>
                );
            }       
        }

        return (
            <>
                <Helmet>
                    <title>{'Window to the Soul #' + int_id + ' - ' + AppName}</title>
                    <meta name="description" content={'Window to the Soul #' + int_id + ' - ' + AppName} />
                    <meta name="keywords" content={'Window to the Soul #' + int_id + ' - ' + AppName}/>
                    <meta property="og:title" content={'Window to the Soul #' + int_id + ' - ' + AppName} />
                    <meta property="og:url" content={AppUrl+'/window-to-the-soul/'+int_id} />
                    <meta property="og:description" content={'Window to the Soul #' + int_id + ' - ' + AppName} />
                    <meta property="og:image" content={"https://api.hypercube.art/images/collection/1/token/"+int_id+".jpg"} />
                    <link rel="canonical" href={AppUrl+'/window-to-the-soul/'+int_id}  />
                </Helmet>

                {/*
                {(int_id <= systemTotalSupply)?(<>
                */}
                    <section className='center_content'>
                        <h1 className="token-title">{'Window to the Soul #' + int_id}</h1>
                        <div className="token-page-image-container">
                            <iframe src={"https://api.hypercube.art/canvas/1/"+int_id} width="600px" height="600px" title="Hypercube"></iframe>
                        </div>
                                
                        <div className="token-page-traits">{traitsList}</div>

                        <div className="token-page-bottom-info">
                            Check on:
                            <a href={'https://opensea.io/assets/0xb93721e73b67686c5a2607ae7c9189887e03e694/'+int_id} title={'Token '+int_id} target="_blank" rel="noreferrer nofollow noopener" className="token-page-marketplace-link" >OpenSea</a>
                        </div>
                        
                        <div className="token-page-bottom-info">
                            Share: 
                            <a href={'http://twitter.com/share?text=Check%20out%20my%20Window%20to%20the%20Soul%20№'+int_id+'!%20@hypercube_art%20%23NFT&url=https://hypercube.art/window-to-the-soul/'+int_id} title={'Token '+int_id} target="_blank" rel="noreferrer nofollow noopener" className="token-page-share-link-tw"><span>Twitter</span></a>
                        </div>
        

                        {(tokenOwner !== 0)?<><div className="icb-container-buy-address">Owner: {tokenOwner}</div></>:''}

                        <div className="token-page-prev-next">
                            <div className="token-page-prev">{(int_id > 1)?(<Link to={'/window-to-the-soul/'+(int_id-1)+'#top'} smooth title={'Token '+(int_id-1)}>{'<< Token '+(int_id-1)}</Link>):""}</div>
                            <div className="token-page-next">{(int_id < systemTotalSupply)?(<Link to={'/window-to-the-soul/'+(int_id+1)+'#top'} smooth title={'Token '+(int_id+1)}>{'Token '+(int_id+1)+' >>'}</Link>):""}</div>
                        </div>
                    </section>

                {/*
                </>):(<>
                    <section>
                        <h1>404</h1>
                        <h2>Token not found</h2>
                    </section>
                </>)}
                */}
            </>
        )
    } else {
        return (
            <><NoMatch setCurrentPage={setCurrentPage} /></>
        )
    }
}

export default Token1