import { useEffect } from 'react';
// import { HashLink as Link } from 'react-router-hash-link';
// import { Helmet } from "react-helmet-async";
// import { Slide } from 'react-slideshow-image';
// import 'react-slideshow-image/dist/styles.css';
// import bigEye from '../../assets/images/art-basel-eye.jpg';
// import Artbaselpremint from "../sections/artbaselpremint/Artbaselpremint";
// import Upcomingdrops from "../sections/upcomingdrops/Upcomingdrops";

const Artbasel = ({AppName, AppUrl, setCurrentPage, LinkDs}) => {

    /*
    const slideImages = [
        '/images/artbasel/artbasel-slider-1.jpg',
        '/images/artbasel/artbasel-slider-2.jpg',
        '/images/artbasel/artbasel-slider-3.jpg',
        '/images/artbasel/artbasel-slider-4.jpg',
        '/images/artbasel/artbasel-slider-5.jpg',
        '/images/artbasel/artbasel-slider-6.jpg',
        '/images/artbasel/artbasel-slider-7.jpg',
        '/images/artbasel/artbasel-slider-8.jpg',
        '/images/artbasel/artbasel-slider-9.jpg',
        '/images/artbasel/artbasel-slider-10.jpg',
        '/images/artbasel/artbasel-slider-11.jpg',
        '/images/artbasel/artbasel-slider-12.jpg',
        '/images/artbasel/artbasel-slider-13.jpg',
        '/images/artbasel/artbasel-slider-14.jpg',
        '/images/artbasel/artbasel-slider-15.jpg',
        '/images/artbasel/artbasel-slider-16.jpg',
        '/images/artbasel/artbasel-slider-17.jpg',
        '/images/artbasel/artbasel-slider-18.jpg',
        '/images/artbasel/artbasel-slider-19.jpg'
      ];
    */

    useEffect(() => {
		setCurrentPage('art-basel');
	}, [setCurrentPage]);

    return (
        <>
            ArtBasel
            {/*
            <Helmet>
                <title>{AppName + ' at Art Basel'}</title>
                <meta name="description" content={AppName + ' at Art Basel'} />
                <meta name="keywords" content={AppName + ' at Art Basel'} />
                <meta property="og:title" content={AppName + ' at Art Basel'} />
                <meta property="og:url" content={AppUrl+'/art-basel'} />
                <meta property="og:description" content={AppName + ' at Art Basel'} />
                <meta property="og:image" content={AppUrl + '/share.jpg'} />
                <link rel="canonical" href={AppUrl+'/art-basel'} />
            </Helmet>
            <section>
                <div>
                    <img src={bigEye} title="Hypercube" alt="Hypercube" style={{width: "100%"}} />
                </div>
                <h2>WOW, THANK YOU!</h2>
                <p>Our sneak peek preview during Art Basel Miami was a huge success, thanks to everyone who came to visit! Thousands and thousands of people came by our booth, snapped photos, and had great conversations. We enjoyed meeting all of you.</p>

                <p>The highlight of our week was <a href="https://www.miamiherald.com/entertainment/visual-arts/art-basel/article256140327.html" title="Miami Herald selecting us" target="_blank" rel="noreferrer nofollow noopener">Miami Herald selecting us</a> as one of the "<b>must see</b>" projects out of the many thousands of art projects at #MiamiArtWeek.</p>

                <p className="center_content">
                <img src="/images/artbasel_miamiherald.png" alt="Miami Herald" title="Miami Herald" style={{width: "720px", margin: "10px auto 20px"}} />
                </p>

                <div style={{width: "100%", maxWidth: "600px", margin: "0 auto 10px", height: "338px"}}>
                <Slide easing="ease">
                <div className="each-slide">
                    <div style={{'backgroundImage': `url(${slideImages[0]})`}}>
                    <span>&nbsp;</span>
                    </div>
                </div>
                <div className="each-slide">
                    <div style={{'backgroundImage': `url(${slideImages[1]})`}}>
                    <span>&nbsp;</span>
                    </div>
                </div>
                <div className="each-slide">
                    <div style={{'backgroundImage': `url(${slideImages[2]})`}}>
                    <span>&nbsp;</span>
                    </div>
                </div>
                <div className="each-slide">
                    <div style={{'backgroundImage': `url(${slideImages[3]})`}}>
                    <span>&nbsp;</span>
                    </div>
                </div>
                <div className="each-slide">
                    <div style={{'backgroundImage': `url(${slideImages[4]})`}}>
                    <span>&nbsp;</span>
                    </div>
                </div>
                <div className="each-slide">
                    <div style={{'backgroundImage': `url(${slideImages[5]})`}}>
                    <span>&nbsp;</span>
                    </div>
                </div>
                <div className="each-slide">
                    <div style={{'backgroundImage': `url(${slideImages[6]})`}}>
                    <span>&nbsp;</span>
                    </div>
                </div>
                <div className="each-slide">
                    <div style={{'backgroundImage': `url(${slideImages[7]})`}}>
                    <span>&nbsp;</span>
                    </div>
                </div>
                <div className="each-slide">
                    <div style={{'backgroundImage': `url(${slideImages[8]})`}}>
                    <span>&nbsp;</span>
                    </div>
                </div>
                <div className="each-slide">
                    <div style={{'backgroundImage': `url(${slideImages[9]})`}}>
                    <span>&nbsp;</span>
                    </div>
                </div>
                <div className="each-slide">
                    <div style={{'backgroundImage': `url(${slideImages[10]})`}}>
                    <span>&nbsp;</span>
                    </div>
                </div>
                <div className="each-slide">
                    <div style={{'backgroundImage': `url(${slideImages[11]})`}}>
                    <span>&nbsp;</span>
                    </div>
                </div>
                <div className="each-slide">
                    <div style={{'backgroundImage': `url(${slideImages[12]})`}}>
                    <span>&nbsp;</span>
                    </div>
                </div>
                <div className="each-slide">
                    <div style={{'backgroundImage': `url(${slideImages[13]})`}}>
                    <span>&nbsp;</span>
                    </div>
                </div>
                <div className="each-slide">
                    <div style={{'backgroundImage': `url(${slideImages[14]})`}}>
                    <span>&nbsp;</span>
                    </div>
                </div>
                <div className="each-slide">
                    <div style={{'backgroundImage': `url(${slideImages[15]})`}}>
                    <span>&nbsp;</span>
                    </div>
                </div>
                <div className="each-slide">
                    <div style={{'backgroundImage': `url(${slideImages[16]})`}}>
                    <span>&nbsp;</span>
                    </div>
                </div>
                <div className="each-slide">
                    <div style={{'backgroundImage': `url(${slideImages[17]})`}}>
                    <span>&nbsp;</span>
                    </div>
                </div>
                <div className="each-slide">
                    <div style={{'backgroundImage': `url(${slideImages[18]})`}}>
                    <span>&nbsp;</span>
                    </div>
                </div>
                </Slide>
            </div>

            <h2>RECAP</h2>
            <p>Below are the Artist's Choice pieces we exhibited, which are now <b>SOLD OUT</b>.</p>
            <p>For more information on the <Link to={'/#top'} smooth title="main sale click here">main sale click here
            </Link>, and don't forget to <a href={LinkDs} title="join our Discord" target="_blank" rel="noreferrer nofollow noopener">join our Discord</a> for giveaways and early access</p>

            <Artbaselpremint />

            </section>

            <Upcomingdrops LinkDs={LinkDs} />
            */}
        </>
    )
}

export default Artbasel
