import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { PaperSDKProvider } from '@paperxyz/react-client-sdk';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';

const paperClientId = process.env.REACT_APP_PAPER_CLIENT_ID;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PaperSDKProvider chainName="Ethereum" clientId={paperClientId}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </PaperSDKProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
