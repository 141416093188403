import { useState, useEffect } from 'react';
import { Helmet } from "react-helmet-async";

// Components
import ConnectPage from '../common/ConnectPage';
import TokensList from '../common/TokensList';

import './Wallet.css';

const Wallet = ({ AppName, AppUrl, setCurrentPage, web3Connect, web3ConnectTorus, isProvider, userAddress, provider, web3Disconnect, chainID, CollectionContract1, LinkOpensea_1, LinkEtherscan_1, CollectionContract2core, LinkOpensea_2, LinkEtherscan_2}) => {
    
    const [userTokensCount1, setUserTokensCount1] = useState(0);
    const [userTokensCount2, setUserTokensCount2] = useState(0);
    const [userTokens2, setUserTokens2] = useState([]);

    const TokensListItemAlt = 'Token';
    const TokensListWithLink = true;
    const collectionId = 2;
    const collectionUrl = 'chrysanthemum/';
    const withTitle = true;
    const TokensListToOpensea = false;

    useEffect(() => {
		setCurrentPage('wallet');
	}, [setCurrentPage]);

    useEffect(() => {
        getUserTokensCount1();
        getUserTokensCount2();
        getUserTokens2();
    },[userAddress]);

    const getUserTokensCount1 = async (e) => {
        if(isProvider){
            const resultUserTokensCount1 = await CollectionContract1.methods.balanceOf(userAddress).call();
            if(resultUserTokensCount1){
                setUserTokensCount1(resultUserTokensCount1);
                // console.log('Tokens count: ' + resultUserTokensCount1);
            }
        }
    }

    const getUserTokensCount2 = async (e) => {
        if(isProvider){
            const resultUserTokensCount2 = await CollectionContract2core.methods.balanceOf(userAddress).call();
            if(resultUserTokensCount2){
                setUserTokensCount2(resultUserTokensCount2);
                //console.log('Tokens count: ' + resultUserTokensCount2);
            }
        }
    }

    const getUserTokens2 = async (e) => {
        if(isProvider){
            const resultUserTokens2 = await CollectionContract2core.methods.tokensOfOwner(userAddress).call();
            if(resultUserTokens2){
                setUserTokens2(resultUserTokens2);
                //console.log(resultUserTokens2);
            }
        }
    }
    
    return (
        <>
            <Helmet>
                <title>{'Wallet - ' + AppName}</title>
                <meta name="description" content={'Wallet - ' + AppName} />
                <meta name="keywords" content='Wallet' />
                <meta property="og:title" content={'Wallet - ' + AppName} />
                <meta property="og:url" content={AppUrl+'/wallet'} />
                <meta property="og:description" content={'Wallet - ' + AppName} />
                <meta property="og:image" content={AppUrl + '/share.jpg'} />
                <link rel="canonical" href={AppUrl+'/wallet'} />
            </Helmet>

            <div className="page-text" >
                <h1 className="wallet-title">Your wallet</h1>
                    {(!isProvider)?(
                        <div className="whitelist-status-container-connect">
                            <ConnectPage web3Connect={web3Connect} web3ConnectTorus={web3ConnectTorus} />
                        </div>
                    ):(
                        <>
                            <div className="wallet-address">Your address: {userAddress}</div>

                            {(chainID !== 1)?(<>
                                <div className="metamask-chain-msg metamask-msg-error big-error">You should switch to Ethereum MainNet in your Wallet!</div><br />
                            </>) : (<> 

                                {/* Collection 1 */}
                                <div className="wallet-contract-container">
                                    <h2>Drop 001: Window to the soul</h2>
                                    <div className="icb-container-your-tokens">
                                        <h3>You have: <span>{userTokensCount1}</span>{(userTokensCount1 === 1)?(' token'):(' tokens')}</h3>
                                        <div>
                                            {LinkOpensea_1 && <a href={LinkOpensea_1} title="opensea"  target="_blank" rel="noreferrer nofollow noopener">Opensea</a>}
                                            {LinkEtherscan_1 && <a href={LinkEtherscan_1} title="Contract"  target="_blank" rel="noreferrer nofollow noopener">Contract</a>}
                                        </div>
                                    </div>
                                </div>

                                {/* Collection 2 */}
                                <div className="wallet-contract-container">
                                    <h2>Drop 002: Chrysanthemum</h2>
                                    <div className="icb-container-your-tokens">
                                        <h3>You have: <span>{userTokensCount2}</span>{(userTokensCount2 === 1)?(' token'):(' tokens')}</h3>
                                            
                                        {(userTokens2.length > 0)?(<>
                                            <p>Your tokens:</p>
                                            <TokensList ListItems={userTokens2} ListItemAlt={TokensListItemAlt} ListWithLink={TokensListWithLink} collectionId={collectionId} withTitle={withTitle} collectionUrl={collectionUrl} TokensListToOpensea={TokensListToOpensea}/>
                                        </>):""}

                                        <div>
                                            {LinkOpensea_2 && <a href={LinkOpensea_2} title="opensea"  target="_blank" rel="noreferrer nofollow noopener">Opensea</a>}
                                            {LinkEtherscan_2 && <a href={LinkEtherscan_2} title="Contract"  target="_blank" rel="noreferrer nofollow noopener">Contract</a>}
                                        </div>
                                    </div>
                                </div>

                            </>)}

                            <div className="wallet-disconnect" onClick={web3Disconnect}>Disconnect wallet</div>
                        </>
                    )}
            </div>
        </>
    )
}

export default Wallet
