import './NewArtists.css';

const NewArtists = () => {
    return (
        <div className="new-artists-bottom">
            <h4>ARTISTS:<br />LET'S LAUNCH YOUR<br /><span><i>NEW PROJECT</i></span></h4>
            <p>FILL IN <a href="https://docs.google.com/forms/d/e/1FAIpQLSdu72IlSfec9GEZ7aSeILVAWwWQiKVGQ5X3TnO2lY0Wocc0rg/viewform?usp=send_form" title="Apply" target="_blank" rel="noreferrer nofollow noopener">APPLICATION FORM</a> OR EMAIL <a href="mailto:CONTACT@HYPERCUBE.ART" title="Email">CONTACT@HYPERCUBE.ART</a></p>
        </div> 
    )
}

export default NewArtists