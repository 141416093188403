import { HashLink as Link } from 'react-router-hash-link';

const Footer = ({LinkTw, LinkDs, LinkIn}) => {
    return (
        <footer>
            <div className="footer-social-list">
                <a href={LinkTw} title="Twitter" target="_blank" rel="noreferrer nofollow noopener">Twitter</a>

                <a href={LinkDs} title="Discord" target="_blank" rel="noreferrer nofollow noopener">Discord</a>

                <a href={LinkIn} title="Instagram" target="_blank" rel="noreferrer nofollow noopener">Instagram</a>

                <Link to='/terms-of-use#top' smooth title="Terms of use">Terms of use</Link>

                <Link to='/privacy#top' smooth title="Privacy Policy">Privacy Policy</Link>
            </div>
            <div className="footer_copyright">Copyright &copy; Hypercube. All Rights Reserved.</div>
        </footer> 
    )
}

export default Footer