import { useState, useEffect } from 'react';
import { Helmet } from "react-helmet-async";
//import { HashLink as Link } from 'react-router-hash-link';

// Components
import TokensList from '../common/TokensList';

const Drop2 = ({ AppName, AppUrl, setCurrentPage, LinkOpensea, isProvider, userAddress, CollectionContract2core }) => {
    const [userTokens2, setUserTokens2] = useState([]);

    const TokensListItemAlt = 'Token';
    const TokensListWithLink = true;
    const collectionId = 2;
    const collectionUrl = 'chrysanthemum/';
    const withTitle = true;
    const TokensListToOpensea = true;

    useEffect(() => {
		setCurrentPage('mint-2-paper-done');
	}, [setCurrentPage]);

    useEffect(() => {
        getUserTokens2();
    },[userAddress]);

    const getUserTokens2 = async (e) => {
        if(isProvider){
            const resultUserTokens2 = await CollectionContract2core.methods.tokensOfOwner(userAddress).call();
            if(resultUserTokens2){
                setUserTokens2(resultUserTokens2);
                //console.log(resultUserTokens2);
            }
        }
    }
    
    return (
        <>
            <Helmet>
                <title>{'Chrysanthemum: Thank you - ' + AppName}</title>
                <meta name="description" content={'Chrysanthemum: Thank you - ' + AppName} />
                <meta name="keywords" content={'Chrysanthemum: Thank you - ' + AppName} />
                <meta property="og:title" content={'Chrysanthemum: Thank you - ' + AppName} />
                <meta property="og:url" content={AppUrl+'/mint2/thank-you'} />
                <meta property="og:description" content={'Chrysanthemu: Thank you - ' + AppName} />
                <meta property="og:image" content={AppUrl + '/share.jpg'} />
                <link rel="canonical" href={AppUrl+'/mint2/thank-you'} />
            </Helmet>
            
            <section>
                <h1>Thank you</h1>
                <p>Your credit card payment was successful. We will transfer the new token to your wallet soon!</p>

                {(userTokens2.length > 0)?(<>
                    <div style={{margin: "50px auto"}}>
                        <p>Your tokens:</p>
                        <TokensList ListItems={userTokens2} ListItemAlt={TokensListItemAlt} ListWithLink={TokensListWithLink} collectionId={collectionId} withTitle={withTitle} collectionUrl={collectionUrl} TokensListToOpensea={TokensListToOpensea}/>
                    </div>
                </>):""}

                {LinkOpensea && <p>View our collection at <a href={LinkOpensea} title="OpenSea" target="_blank" rel="noreferrer nofollow noopener">OpenSea</a></p>}
            </section>
        </>
    )
}

export default Drop2