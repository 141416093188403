import React, { useState, useEffect } from 'react';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";

import Web3 from 'web3';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Torus from "@toruslabs/torus-embed";
import { createAlchemyWeb3 } from '@alch/alchemy-web3';

//Abis
import { abiStorage1 } from './abi1';
import { abiStorage2 } from './abi2'; // For Minter Contract
import { abiStorage2core } from './abi2core'; // For Chrysanthemum main contract

// Pages
import Index from './components/pages/Index';
import Drops from './components/pages/Drops';
import Drop1 from './components/pages/Drop1'; // Window to the soul collection page
import Drop2 from './components/pages/Drop2'; // Chrysanthemum collection page
import Mint1 from './components/pages/Mint1'; // Window to the soul Mint Page
import Mint2 from './components/pages/Mint2'; // Chrysanthemum Public Sale page
import Mint2PaperDone from './components/pages/Mint2PaperDone'; // Chrysanthemum: page for successful paper payment
// import Mint2Presale from './components/pages/Mint2Presale'; // Chrysanthemum Presale mint page

import Token1 from './components/pages/Token1'; // Token's page for Window to the soul collection
import Token2 from './components/pages/Token2'; // Token's page for Chrysanthemum collection
import Wallet from './components/pages/Wallet';

// Info Pages
import Artbasel from './components/pages/Artbasel';
import InfoTos from './components/pages/InfoTos';
import InfoPrivacy from './components/pages/InfoPrivacy';

import NoMatch from './components/pages/NoMatch';

// Components
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";

const AppName = 'Hypercube';
const AppUrl = 'https://www.hypercube.art';

// For header and footer
const LinkTw_common = 'https://twitter.com/hypercube_art';
const LinkDs_common = 'https://discord.gg/PZEnZzD2a3';
// const LinkDs_common = 'https://discord.gg/NRchP2em7x';
const LinkIn_common = 'https://www.instagram.com/hypercube_art';

// Contract 1
const LinkTw_1 = 'https://twitter.com/hypercube_art';
const LinkDs_1 = 'https://discord.gg/PZEnZzD2a3';
// const LinkDs_1 = 'https://discord.com/invite/aHfqyHsGuq';
const LinkIn_1 = 'https://www.instagram.com/hypercube_art';
const LinkOpensea_1 = 'https://opensea.io/collection/window-to-the-soul-by-electralina';
const LinkEtherscan_1 = 'https://etherscan.io/address/0xb93721e73b67686c5a2607ae7c9189887e03e694';
const AppSalesLimit1 = 1000;
const contractAddr1 = '0xb93721e73b67686c5a2607ae7c9189887e03e694'; // MainNet
// const contractAddr1 = '0xe5952c52d0f98B0Cf28Fc4bF044fe31c9130062B'; // TestNet Goerli
// const contractAddr1 = '0x9D7BB089aF7573B6A686B52a313a3ee1569C0647'; // TestNet Rinkebi

// Contract 2
const LinkTw_2 = 'https://twitter.com/ChrysanthemApp';
const LinkDs_2 = 'https://discord.gg/NRchP2em7x';
const LinkIn_2 = 'https://www.instagram.com/hypercube_art';
const LinkOpensea_2 = 'https://opensea.io/collection/chrysanthemum-by-luminance';
const LinkEtherscan_2 = 'https://etherscan.io/address/0x8a7baC741D1F54992a5F26DAA90C0c5C3f74a941';
const AppSalesLimit2 = 1440;
const contractAddr2 = '0x854146413561Ad87b25cA9aD5687bE956e81b3F2'; // MainNet Minter Contract
const contractAddr2core = '0x8a7baC741D1F54992a5F26DAA90C0c5C3f74a941'; // MainNet Chrysanthemum Contract
// const contractAddr2 = '0x5d218D2273daC7e85e0C7065c499EA977F79E6EE'; // TestNet Rinkeby Minter Contract
// const contractAddr2core = '0x8529B7e7B24BA6B7F416A7e05ce4D48E27f07f25'; // TestNet Rinkeby Chrysanthemum Contract

const providerOptions = {
  injected: {
    display: {
      name: "MetaMask",
      description: "Connect with the provider in your Browser"
    },
    package: null
  },

  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: process.env.REACT_APP_INFURA_API_KEY,
      network: "mainnet",
      // network: "rinkeby", // TestNet
      qrcodeModalOptions: {
        mobileLinks: [
          "rainbow",
          "metamask",
          "argent",
          "trust",
          "imtoken",
          "pillar"
        ]
      }
    }
  },

  torus: {
    package: Torus, // required
    options: {
      // networkParams: {
        // host: "https://localhost:8545", // optional
        // chainId: 1, // optional
        // networkId: 1// optional
      // },
      // config: {
        // buildEnv: "development" // optional
      // }
    }
  }
};

const web3Modal = new Web3Modal({
  network: "mainnet", // FOR MAIN NET
  // network: "goerli", // for 1 contract test
  // network: "rinkeby", // for 2 contract test
  cacheProvider: true,
  providerOptions
});

let provider = null;
let web3 = null;
let CollectionContract1 = null;
let CollectionContract2 = null;
let CollectionContract2core = null;

// Alchemy System Connect
const alchemyKey = 'https://eth-mainnet.alchemyapi.io/v2/' + process.env.REACT_APP_ALCHEMY_API_KEY; // MainNet
// const alchemyKey = 'https://eth-goerli.alchemyapi.io/v2/' + process.env.REACT_APP_ALCHEMY_API_KEY; // TestNet Goerli - for 1 contract test
// const alchemyKey = 'https://eth-rinkeby.alchemyapi.io/v2/' + process.env.REACT_APP_ALCHEMY_API_KEY; // TestNet Rinkeby - for 2 contract test

const web3sytem = createAlchemyWeb3(alchemyKey)
const SystemContract1 = new web3sytem.eth.Contract(abiStorage1, contractAddr1);
const SystemContract2 = new web3sytem.eth.Contract(abiStorage2, contractAddr2); // Minter Contract
const SystemContract2core = new web3sytem.eth.Contract(abiStorage2core, contractAddr2core); // Chrysanthemum Contract

const convertRate = 1000000000000000000;

const torus = new Torus();

function App() {
  const [currentPage, setCurrentPage] = useState('');

  const [salesLaunched1] = useState(true);
  const [salesLaunched2] = useState(true);
  // const [salesLaunched2presale] = useState(true);    // Presale

  // For Web3Modal
  const [isProvider, setIsProvider] = useState(false);
  const [userAddress, setUserAddress] = useState('0');
  const [chainID, setChainID] = useState(0);
  const [isTorus, setIsTorus] = useState(false);

  // For system
  const [isSystemProvider1, setIsSystemProvider1] = useState(false);
  const [systemTotalSupply1, setSystemTotalSupply1] = useState(0);

  const [isSystemProvider2, setIsSystemProvider2] = useState(true);
  const [systemTotalSupply2, setSystemTotalSupply2] = useState(0);
  const [systemPublicPrice2, setSystemPublicPrice2] = useState(250000000000000000);

  // For Torus Email
  const [torusFirstCheck, setTorusFirstCheck] = useState(true);
  const [torusEmail, setTorusEmail] = useState();
  const [torusInit, setTorusInit] = useState(false);

  useEffect(() => {
    getCachedProvider();
    getUserAddress();

    isSystemProviderConnected1();
    getSystemTotalSupply1();

    isSystemProviderConnected2();
    getSystemTotalSupply2();
    getSystemPublicPrice2();

    getTorusEmail();
  });

  const getTorusEmail = async (e) => {
    if(isTorus){
        if(torusFirstCheck){
            if(!torusInit){
                await torus.init({showTorusButton: false});
                setTorusInit(true);

                const userInfo = await torus.getUserInfo();
                if(userInfo){
                    if(userInfo.email){
                        setTorusEmail(userInfo.email);   
                    }
                    console.log('Get Torus Email');
                    setTorusFirstCheck(false);
                }
            }
            
            const userInfo = await torus.getUserInfo();
            if(userInfo){
                if(userInfo.email){
                    setTorusEmail(userInfo.email);   
                }
                console.log('Get Torus Email');
                setTorusFirstCheck(false);
            }
        }
    }
  } 

  const getCachedProvider = async (e) => {
    if(localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER")){
      web3Connect();
      //console.log("Reconnect");
    }
  }

  const getUserAddress = async (e) => {
    if(isProvider){
      const accounts = await web3.eth.getAccounts();
      if(accounts[0]){
        setUserAddress(accounts[0]);
        //console.log('new account');
        //setUserAddress(accounts[0].toLowerCase());
      } else {
        setUserAddress('0');
        web3Disconnect();
        //console.log('no account');
      }

      const chainId = await web3.eth.getChainId();
      if(chainId){
        setChainID(chainId);
      } else {
        setChainID(0);
      }

      if(provider.isTorus){
        setIsTorus(true);
      } else {
        setIsTorus(false);
      }
    } else {
      setUserAddress('0');
      setChainID(0);
      web3Disconnect();
      setIsTorus(false);
    }
  }

  async function web3Connect() {
    if (!provider) {
      try {
        provider = await web3Modal.connect();
        web3 = new Web3(provider);
        CollectionContract1 = new web3.eth.Contract(abiStorage1, contractAddr1);
        CollectionContract2 = new web3.eth.Contract(abiStorage2, contractAddr2); // Minter Contract
        CollectionContract2core = new web3.eth.Contract(abiStorage2core, contractAddr2core); // Chrysanthemum Contract

        setIsProvider(true);
      } catch (error) {
        // console.log('User rejected the request');
        // console.log(error);
      }
    }
  }

  async function web3ConnectTorus() {
    if (!provider) {
      try {
        provider = await web3Modal.connectTo("torus");
        web3 = new Web3(provider);
        CollectionContract1 = new web3.eth.Contract(abiStorage1, contractAddr1);
        CollectionContract2 = new web3.eth.Contract(abiStorage2, contractAddr2); // Minter Contract
        CollectionContract2core = new web3.eth.Contract(abiStorage2core, contractAddr2core); // Chrysanthemum Contract

        setIsProvider(true);
      } catch (error) {
        // console.log('User rejected the request');
        // console.log(error);
      }
    }
  }

  async function web3Disconnect() {
    web3Modal.clearCachedProvider();
    setIsProvider(false);
    setUserAddress('0');

    setIsTorus(false);
    setTorusFirstCheck(true);
    setTorusEmail();
    // setTorusInit(false);

    provider = null;
  }

  if(provider){
    provider.on("accountsChanged", (accounts) => {
      getUserAddress();
    });

    provider.on("chainChanged", (chainId) => {
      getUserAddress();
    });
  }

  const isSystemProviderConnected1 = () => {
    if(SystemContract1){
        setIsSystemProvider1(true);
    } else {
        setIsSystemProvider1(false);
    }
  }

  const getSystemTotalSupply1 = async (e) => {
    if(isSystemProvider1){
        const resultSystemTotalSupply = await SystemContract1.methods.totalSupply().call();
        if(resultSystemTotalSupply){
            setSystemTotalSupply1(resultSystemTotalSupply);
        }
    } else {
        setSystemTotalSupply1('0');
    }
  }

  const isSystemProviderConnected2 = () => {
    if(SystemContract2){
        setIsSystemProvider2(true);
    } else {
        setIsSystemProvider2(false);
    }
  }

  const getSystemTotalSupply2 = async (e) => {
    if(isSystemProvider2){
        const resultSystemUnclTotalSupply = await SystemContract2.methods.unclaimedSupply().call();
        if(resultSystemUnclTotalSupply){
            setSystemTotalSupply2(AppSalesLimit2 - resultSystemUnclTotalSupply);
        }
    } else {
        setSystemTotalSupply2(0);
    }
  }

  const getSystemPublicPrice2 = async (e) => {
    if(isSystemProvider2){
        const resultPublicPrice = await SystemContract2.methods.publicSalePrice().call();
        if(resultPublicPrice){
          setSystemPublicPrice2(resultPublicPrice);
        }
    } else {
      setSystemPublicPrice2(0);
    }
  }

  return (
    <BrowserRouter>
      <>
        <Header LinkTw={LinkTw_common} LinkDs={LinkDs_common} LinkIn={LinkIn_common} currentPage={currentPage} AppName={AppName} />

        <main>
          <div id="content">
            <Routes>
                <Route path='/' exact strict element={<Index AppName={AppName} AppUrl={AppUrl} setCurrentPage={setCurrentPage} />} />

                <Route path='/drops' exact strict element={<Drops AppName={AppName} AppUrl={AppUrl} setCurrentPage={setCurrentPage} />} />

                {/* Collection 1: Page */}
                <Route path='/window-to-the-soul' exact strict element={<Drop1 AppName={AppName} AppUrl={AppUrl} setCurrentPage={setCurrentPage} LinkTw = {LinkTw_1} LinkDs = {LinkDs_1} LinkIn = {LinkIn_1} LinkOpensea = {LinkOpensea_1} LinkEtherscan ={LinkEtherscan_1} systemTotalSupply = {systemTotalSupply1} AppSalesLimit = {AppSalesLimit1} web3Connect={web3Connect} web3={web3} isProvider={isProvider} userAddress={userAddress} salesLaunched={salesLaunched1} CollectionContract={CollectionContract1} chainID={chainID} />} />

                {/* Collection 1: Public Sale */}
                {/* ЭТУ СТРАНИЦУ НАДО БУДЕТ ПЕРЕБРАТЬ, Я ЕЕ НЕ ТЕСТИЛ ПОСЛЕ ПЕРЕХОДА НА НОВЫЙ ДВИЖОК + web3ConnectTorus*/}
                <Route path='/third-secret-drop' exact strict element={<Mint1 AppName={AppName} AppUrl={AppUrl} setCurrentPage={setCurrentPage} LinkTw = {LinkTw_1} LinkDs = {LinkDs_1} AppSalesLimit = {AppSalesLimit1} web3Connect={web3Connect} web3={web3} isProvider={isProvider} userAddress={userAddress} provider={provider} salesLaunched={salesLaunched1} CollectionContract = {CollectionContract1} chainID={chainID} />} />

                {/* Collection 2: Public Sale */}
                <Route path='/chrysanthemum/mint' exact strict element={<Mint2 AppName={AppName} AppUrl={AppUrl} setCurrentPage={setCurrentPage} LinkTw = {LinkTw_2} LinkDs = {LinkDs_2} AppSalesLimit = {AppSalesLimit2} web3Connect={web3Connect} web3ConnectTorus={web3ConnectTorus} web3={web3} isProvider={isProvider} userAddress={userAddress} provider={provider} salesLaunched={salesLaunched2} CollectionContract = {CollectionContract2} chainID={chainID} systemTotalSupply = {systemTotalSupply2} systemPublicPrice={systemPublicPrice2} convertRate={convertRate} isTorus={isTorus} torusEmail={torusEmail} />} />

                {/* Collection 2: Page */}
                <Route path='/chrysanthemum' exact strict element={<Drop2 AppName={AppName} AppUrl={AppUrl} setCurrentPage={setCurrentPage} LinkTw = {LinkTw_2} LinkDs = {LinkDs_2} LinkIn = {LinkIn_2} LinkOpensea = {LinkOpensea_2} LinkEtherscan ={LinkEtherscan_2} systemTotalSupply = {systemTotalSupply2} AppSalesLimit = {AppSalesLimit2} salesLaunched={salesLaunched2}/>} />

                <Route path="/nft-new-york" exact element={ <Navigate to="/chrysanthemum/mint" /> } />

                {/* Collection 2: Secret PRESALE page - CANCELLED! + web3ConnectTorus */}
                {/* <Route path='/chrysanthemum/presale' exact strict element={<Mint2Presale AppName={AppName} AppUrl={AppUrl} setCurrentPage={setCurrentPage} LinkTw = {LinkTw_2} LinkDs = {LinkDs_2} AppSalesLimit = {AppSalesLimit2} web3Connect={web3Connect} web3={web3} isProvider={isProvider} userAddress={userAddress} provider={provider} salesLaunched={salesLaunched2presale} CollectionContract = {CollectionContract2} chainID={chainID} systemTotalSupply = {systemTotalSupply2} systemPublicPrice={systemPublicPrice2} convertRate={convertRate} isTorus={isTorus}/>} /> */}

                <Route path='/mint2/thank-you' exact strict element={<Mint2PaperDone AppName={AppName} AppUrl={AppUrl} setCurrentPage={setCurrentPage} LinkOpensea={LinkOpensea_2} isProvider={isProvider} userAddress={userAddress} CollectionContract2core={CollectionContract2core} />} />

                <Route path='/window-to-the-soul/:id' exact strict element={<Token1 AppName={AppName} AppUrl={AppUrl} setCurrentPage={setCurrentPage} SystemContract={SystemContract1} isSystemProvider={isSystemProvider1} systemTotalSupply={systemTotalSupply1} />} />

                <Route path='/chrysanthemum/:id' exact strict element={<Token2 AppName={AppName} AppUrl={AppUrl} setCurrentPage={setCurrentPage} SystemContract={SystemContract2core} isSystemProvider={isSystemProvider2} systemTotalSupply={systemTotalSupply2} />} />

                <Route path='/wallet' exact strict element={<Wallet AppName={AppName} AppUrl={AppUrl} setCurrentPage={setCurrentPage} web3Connect={web3Connect} web3ConnectTorus={web3ConnectTorus} isProvider={isProvider} userAddress={userAddress} provider={provider} web3Disconnect={web3Disconnect} chainID={chainID} CollectionContract1 = {CollectionContract1} LinkOpensea_1 = {LinkOpensea_1} LinkEtherscan_1 = {LinkEtherscan_1} CollectionContract2core={CollectionContract2core} LinkOpensea_2 = {LinkOpensea_2} LinkEtherscan_2 = {LinkEtherscan_2}/>} />

                <Route path='/art-basel' exact strict element={<Artbasel AppName={AppName} AppUrl={AppUrl} setCurrentPage={setCurrentPage} />} />

                <Route path='/terms-of-use' exact strict element={<InfoTos AppName={AppName} AppUrl={AppUrl} setCurrentPage={setCurrentPage} />} />

                <Route path='/privacy' exact strict element={<InfoPrivacy AppName={AppName} AppUrl={AppUrl} setCurrentPage={setCurrentPage} />} />

                <Route path="*" element={<NoMatch AppName={AppName} AppUrl={AppUrl} setCurrentPage={setCurrentPage} />} />
            </Routes>
          </div>
        </main>

        <Footer LinkTw={LinkTw_common} LinkDs={LinkDs_common} LinkIn={LinkIn_common} AppName={AppName}/>
      </>
    </BrowserRouter>
  );
}

export default App;
