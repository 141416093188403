import './TeamList.css';

import imgTeam1 from "../../assets/images/team1.jpg";
import imgTeam2 from "../../assets/images/team2.jpg";
import imgTeam3 from "../../assets/images/team3.jpg";
import imgTeam4 from "../../assets/images/team4.jpg";

const TeamList = () => {
    return (
        <section>
            <div className="team_list_item">
                <div className="team_list_title">
                    <a href="https://twitter.com/ryanjunee" title="Ryan.xyz" target="_blank" rel="noreferrer nofollow noopener">
                        <img src={imgTeam1} alt="Ryan.xyz" title="Ryan.xyz" className="team_list_photo" width="200px;" height="200px"/>
                        <div className="team_list_name">Ryan.xyz</div>
                    </a>
                    <div className="team_list_role">Strategy</div>
                </div>
                <div className="team_list_text">
                    <p className="first_p">Ryan Junee has started 6 different companies over the last 15 years worth today more than $400M. He sold one of them to YouTube. He is also an investor in many technology and web3 companies, and was previously a part-time partner at Y Combinator (the world’s most successful startup accelerator that helped create Coinbase, AirBnB, DoorDash, DropBox and more).</p>
                    <p>He has a Master’s Degree from Stanford University and is an experienced programmer and generative artist.</p>
                </div>
            </div>

            <div className="team_list_item">
                <div className="team_list_title">
                    <a href="https://www.instagram.com/alinakaro" title="Electralina" target="_blank" rel="noreferrer nofollow noopener">
                        <img src={imgTeam2} alt="Electralina" title="Electralina" className="team_list_photo" width="200px;" height="200px"/>
                        <div className="team_list_name">Electralina</div>
                    </a>
                    <div className="team_list_role">Art & curation </div>
                </div>
                <div className="team_list_text">
                    <p className="first_p">Alina Karo began her career as a fashion photographer, shooting for Vogue, Harper’s Bazaar, Elle, Marie-Claire and more. She next turned to fine art photography and has exhibited her collections in Paris, New York, Miami and Los Angeles. The rise of NFTs and digital generative art caught her eye and became her newest medium for exploration.</p>
                    <p>With an extensive knowledge of art history and deep network in the fine art community she is the artistic core of HyperCube.</p>
                </div>
            </div>

            <div className="team_list_item">
                <div className="team_list_title">
                    <a href="https://twitter.com/ShendrikSergey" title="Sergey" target="_blank" rel="noreferrer nofollow noopener">
                        <img src={imgTeam3} alt="Sergey" title="Sergey" className="team_list_photo" width="200px;" height="200px"/>
                        <div className="team_list_name">Sergey</div>
                    </a>
                    <div className="team_list_role">Operations</div>
                </div>
                <div className="team_list_text">
                    <p className="first_p">Sergey Shendrik is an entrepreneur and investor with more than 15 years of experience. His first company was sold for $1M before 30 years of age. He believes in the future of blockchain technology and computer art renaissance, and HyperCube is a part of these changes.</p>
                    <p>Has an art and business education, including an Executive MBA. He has produced over 20 art exhibitions in the USA, Russia, Canada.</p>
                </div>
            </div>

            <div className="team_list_item">
                <div className="team_list_title">
                    <a href="https://twitter.com/filmakarov" title="fmc" target="_blank" rel="noreferrer nofollow noopener">
                        <img src={imgTeam4} alt="fmc" title="fmc" className="team_list_photo" width="200px;" height="200px"/>
                        <div className="team_list_name">fmc</div>
                    </a>    
                    <div className="team_list_role">Dev lead</div>
                </div>

                <div className="team_list_text">
                    <p className="first_p">Fil Makarov is an entrepreneur, smart-contracts developer, and blockchain enthusiast. He's a big fan of generative art and an amateur artist himself. He has been in crypto since 2015 and full-time in NFTs since Feb 21.</p>
                    <p>Fil has a blog where he posts educational articles about NFTs. He ensures that HyperCube smart contracts and tech infrastructure use all the best practices, and also helps artists and developers collaborate on the HyperCube platform and find that creative synergy!</p>
                </div>
            </div>
        </section>  
    )
}

export default TeamList