import { HashLink as Link } from 'react-router-hash-link';

import imgDropReview1 from "../../assets/images/drop-review-1.jpg";
import imgDropReview2 from "../../assets/images/drop-review-2.jpg";

import './DropsList.css';

const DropsList = () => {
    return (
        <div className="dropslist">
            <div className="dropslist_item">
                <div className="dropslist_item_info">
                    <div className="dropslist_item_info_label">DROP 002:</div>
                    <Link to='/chrysanthemum#top' smooth title="Chrysanthemum" className="dropslist_item_info_title">Chrysanthemum</Link>
                    <div className="dropslist_item_info_author">by Luminance x TRIPP</div>
                    <div className="dropslist_item_info_description">The first generative NFT using science backed bio-feedback to improve wellness.</div>
                    <Link to='/chrysanthemum#top' smooth title="Chrysanthemum" className="dropslist_item_info_link">More</Link>
                </div>
                
                <div className="dropslist_item_image">
                    <Link to='/chrysanthemum#top' smooth title="Chrysanthemum"><img src={imgDropReview2} alt="Chrysanthemum" /></Link>
                </div>
            </div>

            <div className="dropslist_item">
                <div className="dropslist_item_info">
                    <div className="dropslist_item_info_label">DROP 001:</div>
                    <Link to='/window-to-the-soul#top' smooth title="Window to the soul" className="dropslist_item_info_title">WINDOW TO THE SOUL</Link>
                    <div className="dropslist_item_info_author">by @electralina</div>
                    <div className="dropslist_item_info_description">Our genesis collection. 1,000 unpredictable and infinitely unique digital eyes. What will the window to your soul look like?</div>
                    <Link to='/window-to-the-soul#top' smooth title="Window to the soul" className="dropslist_item_info_link">More</Link>
                </div>
                
                <div className="dropslist_item_image">
                    <Link to='/window-to-the-soul#top' smooth title="Window to the soul"><img src={imgDropReview1} alt="Window to the soul" /></Link>
                </div>
            </div>
        </div>  
    )
}

export default DropsList