import { useEffect } from 'react';
import { Helmet } from "react-helmet-async";

const InfoPrivacy = ({AppName, AppUrl, setCurrentPage}) => {

    useEffect(() => {
		setCurrentPage('info-privacy');
	}, [setCurrentPage]);

    return (
        <>
            <Helmet>
                <title>{'Privacy Policy - ' + AppName}</title>
                <meta name="description" content={'Privacy Policy - ' + AppName} />
                <meta name="keywords" content={'Privacy Policy - ' + AppName} />
                <meta property="og:title" content={'Privacy Policy- ' + AppName} />
                <meta property="og:url" content={AppUrl+'/privacy'} />
                <meta property="og:description" content={'Privacy Policy - ' + AppName} />
                <meta property="og:image" content={AppUrl + '/share.jpg'} />
                <link rel="canonical" href={AppUrl+'/privacy'} />
            </Helmet>

            <section className="info-page-container">
                <h1>Privacy Policy</h1>
                
                <h4>Effective as of June 20, 2022</h4>

                <p>This Privacy Policy describes the privacy practices of HyperCube DAO LLC, (<b>“HyperCube”</b>, <b>“we”</b>, <b>“us”</b>, or <b>“our”</b>), and how we handle personal information that we collect through our digital properties that link to this Privacy Policy, including our website (the <b>“Service”</b>) as well as through social media, our marketing activities and other activities described in this Privacy Policy.</p>

                <h3>Index</h3>
                <ul>
                    <li>Personal information we collect</li>
                    <li>How we use your personal information How we share your personal information Your choices</li>
                    <li>Other sites and services</li>
                    <li>Security</li>
                    <li>International data transfer</li>
                    <li>Children</li>
                    <li>Changes to this Privacy Policy</li>
                    <li>How to contact us</li>
                </ul>

                <h3>Personal information we collect</h3>
                
                <p><b>Personal information artists provide to us</b>. Personal information that artists may provide to us through the Service or otherwise includes:</p>
                
                <ul>
                    <li><p><b>Contact information</b>, such as your first and last name, email and mailing addresses and phone number, and links to your webpage/social media account.</p></li>
                    <li><p><b>Communications information</b> based on exchanges with you, including when you contact us with questions, feedback, or otherwise.</p></li>
                    <li><p><b>Marketing information</b>, such as your preferences for receiving our marketing communications and details about your engagement with them.</p></li>
                    <li><p><b>Social media information</b>, such as information you use to login to, and posts you make on, our Discord server.</p></li>
                    <li><p><b>Art-related information</b> about works that you create and sell through the Service, such as your username/artist name and names and descriptions of your works.</p></li>
                    <li><p><b>Payment information</b>, such as your Ethereum wallet address.</p></li>
                    <li><p><b>Transaction information</b> such as your sale history.</p></li>
                    <li><p><b>Government-issued identification</b> numbers such as your tax identification number to facilitate monetary payments to/from you.</p></li>
                    <li><p><b>Other information</b> not specifically listed here, which we will use as described in this Privacy Policy or as otherwise disclosed at the time of collection.</p></li>
                </ul>

                <p><b>Personal information users provide to us</b>. Personal information that users (meaning digital property visitors) of the Service provide to us through the Service or otherwise may include:</p>

                <ul>
                    <li><p><b>Contact information</b> such as your first and last name, salutation, email address, billing and mailing addresses, and phone number.</p></li>
                    <li><p><b>Communications information</b> based on exchanges with you, including when you contact us with questions, feedback, or otherwise.</p></li>
                    <li><p><b>Marketing information</b>, such as your preferences for receiving our marketing communications and details about your engagement with them.</p></li>
                    <li><p><b>Social media information</b>, such as information you use to login to, and posts you make on, our Discord server.</p></li>
                    <li><p><b>Payment information</b>, such as your Ethereum wallet address needed to complete your transactions.</p></li>
                    <li><p><b>Transaction information</b> such as your purchase and return history.</p></li>
                    <li><p><b>Other information</b> not specifically listed here, which we will use as described in this Privacy Policy or as otherwise disclosed at the time of collection.</p></li>
                </ul>

                <p><b>Third party sources.</b> We may combine personal information we receive from you with personal information we obtain from other sources, such as social media platforms.</p>

                <p><b>Automatic data collection.</b> We, our service providers, and our business partners may automatically log information about you, your computer or mobile device, and your interaction over time with the Service, our communications and other online services, such as:</p>

                <ul>
                    <li><p><b>Device data</b>, such as your computer's or mobile device's operating system type and version, manufacturer and model, browser type, screen resolution, RAM and disk size, CPU usage, device type (e.g., phone, tablet), IP address, unique identifiers (including identifiers used for advertising purposes), language settings, mobile device carrier, radio/network information (e.g., WiFi, LTE, 3G), and general location information such as city, state or geographic area.</p></li>
                    <li><p><b>Online activity data</b>, such as pages or screens you viewed, how long you spent on a page or screen, the website you visited before browsing to the Service, navigation paths between pages or screens, information about your activity on a page or screen, access times, and duration of access, and whether you have opened our marketing emails or clicked links within them.</p></li>
                </ul>

                <p>Cookies and similar technologies. Like many online services, we may use the following technologies:</p>

                <ul>
                    <li><p><b>Cookies</b>, which are text files that websites store on a visitor's device to uniquely identify the visitor's browser or to store information or settings in the browser for the purpose of helping you navigate between pages efficiently, remembering your preferences, enabling functionality, helping us understand user activity and patterns and facilitating analytics.</p></li>
                    <li><p><b>Local storage technologies</b>, like HTML5 and Flash, that provide cookie-equivalent functionality but can store larger amounts of data, including on your device outside of your browser in connection with specific applications.</p></li>
                    <li><p><b>Web beacons</b>, also known as pixel tags or clear GIFs, which are used to demonstrate that a webpage or email was accessed or opened, or that certain content was viewed or clicked.</p></li>
                </ul>

                <h3>How we use your personal information</h3>

                <p>We may use your personal information for the following purposes or as otherwise described at the time of collection:</p>

                <p><b>Service delivery.</b> We may use your personal information to:</p>

                <ul>
                    <li><p>provide, operate and improve the Service and our business;</p></li>
                    <li><p>process your payments and complete transactions with you;</p></li>
                    <li><p>communicate with you about the Service, including by sending our newsletter via email;</p></li>
                    <li><p>understand your needs and interests, and personalize your experience with the Service and our communications; and</p></li>
                    <li><p>provide support for the Service, respond to your requests, questions and feedback.</p></li>
                </ul>

                <p><b>Research and development.</b> We may use your personal information for research and development purposes, including to analyze and improve the Service and our business. As part of these activities, we may create aggregated, de-identified or other anonymous data from personal information we collect. We make personal information into anonymous data by removing information that makes the data personally identifiable to you. We may use this anonymous data and share it with third parties for our lawful business purposes, including to analyze and improve the Service and promote our business.</p>
                
                <p><b>Marketing.</b> We may send you HyperCube-related direct marketing communications as permitted by law, including by email. You may opt-out of our marketing communications as described in the Opt-out of marketing section below.</p>

                <p><b>Compliance and protection.</b> We may use your personal information to:</p>

                <ul>
                    <li><p>comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas or requests from government authorities;</p></li>
                    <li><p>protect our, your or others' rights, privacy, safety or property (including by making and defending legal claims);</p></li>
                    <li><p>audit our internal processes for compliance with legal and contractual requirements and internal policies;</p></li>
                    <li><p>enforce the terms and conditions that govern the Service; and</p></li>
                    <li><p>prevent, identify, investigate and deter fraudulent, harmful, unauthorized, unethical or illegal activity, including cyberattacks and identity theft.</p></li>
                </ul>

                <h3>How we share your personal information</h3>
                
                <p>We may share your personal information with the following parties and as otherwise described in this Privacy Policy or at the time of collection:</p>
                
                <p><b>Service providers.</b> Third parties that provide services on our behalf or help us operate the Service or our business (such as hosting, information technology, payment processors, customer support, email delivery, and website analytics services).</p>
                
                <p><b>Professional advisors.</b> Professional advisors, such as lawyers, auditors, bankers and insurers, where necessary in the course of the professional services that they render to us.</p>
                
                <p><b>Authorities and others.</b> Law enforcement, government authorities, and private parties, as we believe in good faith to be necessary or appropriate for the compliance and protection purposes described above.</p>
                
                <p><b>Business transferees.</b> Acquirers and other relevant participants in business transactions (or negotiations for such transactions) involving a corporate divestiture, merger, consolidation, acquisition, reorganization, sale or other disposition of all or any portion of the business or assets of, or equity interests in, HyperCube or our affiliates (including, in connection with a bankruptcy or similar proceedings).</p>
                
                <p>Please keep in mind that whenever you voluntarily make your personal information available for viewing by third parties or the public on or through our Service, including our Discord server, that information can be seen, collected and used by others. We are not responsible for any use of such information by others.</p>

                <h3>Your choices</h3>
                
                <p>You have the following choices with respect to your personal information.</p>
                
                <p><b>Opt-out of marketing communications.</b> You may opt out of marketing-related emails by following the opt-out or unsubscribe instructions at the bottom of the email. You may continue to receive service-related and other non-marketing emails.</p>
                
                <p><b>Cookies.</b> Most browser settings let you delete and reject cookies placed by websites. Many browsers accept cookies by default until you change your settings. If you do not accept cookies, you may not be able to use all functionality of the Service and it may not work properly. For more information about cookies, including how to see what cookies have been set on your browser and how to manage and delete them, visit www.allaboutcookies.org.</p>
                
                <p><b>Local storage.</b> You may be able to limit use of HTML5 cookies in your browser settings.</p>
                
                <p><b>Analytics.</b> We use Google Analytics to help us understand user activity on the Service. You can learn more about Google Analytics cookies at <a href="https://developers.google.com/analytics/resources/concepts/gaConceptsCookies" title="Cookies" target="_blank" rel="noreferrer nofollow noopener">https://developers.google.com/analytics/resources/concepts/gaConceptsCookies</a> and about how Google protects your data at <a href="http://www.google.com/analytics/learn/privacy.html" title="Privacy" target="_blank" rel="noreferrer nofollow noopener">http://www.google.com/analytics/learn/privacy.html</a>. You can prevent the use of Google Analytics relating to your use of our sites by downloading and installing a browser plugin available at <a href="https://tools.google.com/dlpage/gaoptout?hl=en" title="Analytics" target="_blank" rel="noreferrer nofollow noopener">https://tools.google.com/dlpage/gaoptout?hl=en</a>.</p>
                
                <p><b>Do Not Track.</b> Some Internet browsers may be configured to send “Do Not Track” signals to the online services that you visit. We currently do not respond to “Do Not Track” or similar signals. To find out more about “Do Not Track,” please visit <a href="http://www.allaboutdnt.com" title="dnt" target="_blank" rel="noreferrer nofollow noopener">http://www.allaboutdnt.com</a>.</p>
                
                <p><b>Declining to provide information.</b> We need to collect personal information to provide certain services. If you do not provide the information we identify as required or mandatory, we may not be able to provide those services.</p>
                
                <h3>Other sites and services</h3>
                
                <p>The Service may contain links to websites and other online services operated by third parties. In addition, our content may be integrated into web pages or other online services that are not associated with us. These links and integrations are not an endorsement of, or representation that we are affiliated with, any third party. We do not control websites or online services operated by third parties, and we are not responsible for their actions.</p>

                <h3>Security</h3>
                
                <p>We employ a number of technical, organizational and physical safeguards designed to protect the personal information we collect. However, no security measures are failsafe and we cannot guarantee the security of your personal information.</p>

                <h3>International data transfer</h3>
                
                <p>We are headquartered in the United States and may use service providers that operate in other countries. Your personal information may be transferred to the United States or locations where privacy laws may not be as protective as those in your state, province, or country.</p>
                
                <h3>Children</h3>
                
                <p>The Service is not intended for use by children under 16 years of age. If we learn that we have collected personal information through the Service from a child under 16 without the consent of the child's parent or guardian as required by law, we will delete it.</p>

                <h3>Changes to this Privacy Policy</h3>
                
                <p>We reserve the right to modify this Privacy Policy at any time. If we make material changes to this Privacy Policy, we will notify you by updating the date of this Privacy Policy and posting it on the Service. If required by law we will also provide notification of changes in another way that we believe is reasonably likely to reach you, such as via email or another manner through the Service. Any modifications to this Privacy Policy will be effective upon our posting the modified version (or as otherwise indicated at the time of posting). In all cases, your use of the Service after the effective date of any modified Privacy Policy indicates your acceptance of the modified Privacy Policy.</p>

                <h3>How to contact us</h3>
                
                <p>You can reach us by email at <a href="mailto:contact@hypercube.art" title="contact" target="_blank" rel="noreferrer nofollow noopener">contact@hypercube.art</a> or at the following mailing address:</p>

                <p>HyperCube DAO LLC<br />18117 Biscayne Blvd #3022<br />Miami, FL 33160</p>
                
            </section>
        </>
    )
}

export default InfoPrivacy
