import { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from "react-helmet-async";

// Components
import ConnectPage from '../common/ConnectPage';

import './Mint.css';

const apiPath = '/api';
const client = axios.create({
  baseURL: apiPath,
  headers: { "Content-type": "application/json" } 
});

const Mint2 = ({ AppName, AppUrl, setCurrentPage, LinkTw, LinkDs, AppSalesLimit, web3Connect, web3ConnectTorus, web3, isProvider, userAddress, provider, salesLaunched, CollectionContract, chainID, systemTotalSupply, systemPublicPrice, convertRate, isTorus, torusEmail}) => {

    useEffect(() => {
		setCurrentPage('mint2');
	}, [setCurrentPage]);

    const [tokenPrice, setTokenPrice] = useState(250000000000000000);
    const [tokenAmount, setTokenAmount] = useState(1);
    const [maxAmount, setMaxAmount] = useState(10);
    const [totalSupply, setTotalSupply] = useState(0);
    const [transactionState, setTransactionState] = useState(0);
    const [minted, setMinted] = useState(false);
    
    const [userBalance, setUserBalance] = useState(0);
    const [gasPrice, setGasPrice] = useState(30);

    // For Paper payments
    const [userEmail, setUserEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [paperTransactionState, setPaperTransactionState] = useState(0);
    const [isPaperError, setIsPaperError] = useState(false);

    // Paper Transaction States:
    // 0: Normal - ready to send
    // 1: Waiting for permission from the online payment system
    // 2: Redirecting to payment system

    // For Torus Email
    const [torusFirstCheck, setTorusFirstCheck] = useState(true);

    useEffect(() => {
        getTotalSupply();
        getTokenPrice();

        getWalletBalance();
        getRightBalance();

        getGasPrice();

        getTorusEmail();
    });

    const getTorusEmail = async (e) => {
        if(isTorus){
            if(torusEmail){
                if(torusFirstCheck){
                    setUserEmail(torusEmail);
                    setValidEmail(true);
                    setTorusFirstCheck(false);
                }
            }
        }
    }

    const getTotalSupply = async (e) => {
        if(isProvider){
            const resultSystemUnclTotalSupply = await CollectionContract.methods.unclaimedSupply().call();
            if(resultSystemUnclTotalSupply){
                setTotalSupply(AppSalesLimit - resultSystemUnclTotalSupply);
            }
        }
    }

    const getTokenPrice = async (e) => {
        if(isProvider){
            const resultTokenPrice = await CollectionContract.methods.publicSalePrice().call();
            if(resultTokenPrice){
                // console.log(resultTokenPrice);
                setTokenPrice(resultTokenPrice);
            }
        }
    }

    const getWalletBalance = async (e) => {
        if(web3){
            if(isProvider){
                if(userAddress !== '0'){
                    const resultBalance = await web3.eth.getBalance(userAddress);
                    if(resultBalance){
                        setUserBalance(resultBalance);
                    }
                }
            }
        }
    }

    const getRightBalance = async (e) => {
        if(transactionState === 1 || transactionState === 2 || transactionState === 4){
        } else {
            if(tokenAmount){
                if(tokenPrice*tokenAmount/convertRate <= userBalance/convertRate){
                    setTransactionState(0);
                } else {
                    setTransactionState(3);
                }
            }else{
                setTransactionState(0);
            }
        }
    }

    const getGasPrice = () => {
        fetch('https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=83NNEWF158AJ695GKEEA7HSKNGNZIGQHM6')
			  .then((response) => {
			    return response.json();
			  })
			  .then((data) => {
			    setGasPrice(data.result.ProposeGasPrice);
			});
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if( (transactionState === 0 || transactionState === 1) && (tokenAmount >= 1 || tokenAmount <= maxAmount) ){
            orderToken();
        }
    }
    
    const orderToken = async(e) => {
        setIsPaperError(false);
        setMinted(false);
        setTransactionState(0);
        if(isProvider){
            if(totalSupply < AppSalesLimit){
                if(userAddress !== '0'){
                    if(tokenAmount > 0){
                        if(tokenPrice > 0){
                            setTransactionState(4);
                            
                            await CollectionContract.methods.publicOrder(userAddress, tokenAmount).send({ from: userAddress, value: tokenPrice*tokenAmount})
                            .on('transactionHash', function(hash){
                                // console.log('transactionHash');
                                setTransactionState(2);
                            })
                            .on("error", function(error) {
                                // console.log('error');
                                console.log(error.message);
                                setTransactionState(1);
                            })
                            .on("receipt", function(receipt) {
                                //console.log('Mint done!');                                    
                                setMinted(true);
                                setTransactionState(0);
                                window.location.replace(process.env.REACT_APP_M2_REDIRECT);
                            });
                        }
                    }
                }
            }
        }
    }

    const checkAmount = (e) => {
        setTransactionState(0);

        var amount = e.target.value;
        amount = amount.replace(/[^\d]/g, '');

        if(amount){
            amount = parseInt(amount, 10);
            if(amount > maxAmount) {
                amount = maxAmount;
            }

            if(amount < 1){
                amount = 1;
            }
            setTokenAmount(amount);
        } else {
            setTokenAmount();
        }
    }

    // For Paper payments
    const checkEmail = (e) => {
        setIsPaperError(false);

        var email = e.target.value;
        if(email){
            let mail_format = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(email.match(mail_format)){
                setValidEmail(true);
            } else {
                setValidEmail(false);
            }
            setUserEmail(email);
        } else {
            setUserEmail('');
            setValidEmail(false);
        }
    }

    const handleSubmitPaper = (evt) => {
        evt.preventDefault();
        if(paperTransactionState === 0){
            if(validEmail){
                orederByPaper();
            }
        }
    }

    const orederByPaper = async(e) => {
        setIsPaperError(false);
        if(userAddress !== '0'){
            if(userEmail !== ''){
                setPaperTransactionState(1);

                client.post(`/paper/checkout`, { uaddress: userAddress, uemail: userEmail},{headers: {'APIMKEY': process.env.REACT_APP_API_M_KEY}})
                .then(res => {    
                    if(res.status === 200){
                        setPaperTransactionState(2);
                        let redirect_url = res.data.checkout_url;
                        if(redirect_url){
                            window.location.replace("https://paper.xyz/checkout/" + process.env.REACT_APP_PAPER_CHECKOUT_ID + "/one-time-link/" + redirect_url);
                        } else {
                            setIsPaperError(true);
                            setPaperTransactionState(0);
                            console.log('Merchant error');
                        }
                    } else {
                        setIsPaperError(true);
                        setPaperTransactionState(0);
                        console.log('Merchant error');
                    }
                }).catch(error => {
                    setIsPaperError(true);
                    setPaperTransactionState(0);
                    console.log('Merchant error');
                });
            }
        }
    }
    
    return (
        <>
            <Helmet>
                <title>{'Chrysanthemum Mint - ' + AppName}</title>
                <meta name="description" content={'Chrysanthemum Mint - ' + AppName} />
                <meta name="keywords" content='Chrysanthemum Mint' />
                <meta property="og:title" content={'Chrysanthemum Mint - ' + AppName} />
                <meta property="og:url" content={AppUrl+'/chrysanthemum/mint'} />
                <meta property="og:description" content={'Chrysanthemum Mint - ' + AppName} />
                <meta property="og:image" content={AppUrl + '/share.jpg'} />
                <link rel="canonical" href={AppUrl+'/chrysanthemum/mint'} />
            </Helmet>

            <div className="page-text">
                
                
                {(salesLaunched)?(<>
                    {(systemTotalSupply < AppSalesLimit)?(<>
                        
                        <div className="icb-container">
                            <div className="token-minted-counter">{systemTotalSupply} / {AppSalesLimit} MINTED</div> 

                            <div className="icb-container-drop-title">Chrysanthemum</div>
                            <div className="icb-container-drop-subtitle">Public Mint</div>

                            <div className="icb-container-top-total">Price: <span>{(systemPublicPrice/convertRate).toFixed(2)} ETH</span></div>
                            
                            {(!isProvider)?(
                                <div className="whitelist-status-container-connect" style={{marginBottom: "25px"}}>
                                    <ConnectPage web3Connect={web3Connect} web3ConnectTorus={web3ConnectTorus} />
                                </div>
                            ):(<>
                                <div className="token-payment-methods">
                                    {(!isTorus)?(<>
                                    
                                    {(paperTransactionState === 1 || paperTransactionState === 2)?(<>
                                        <div className="token-payment-methods-item-disable">
                                            This payment method is not available while you are working with another payment method!
                                        </div>
                                    </>):(<>
                                        <div className="token-payment-methods-item">
                                            <h2 className="token-payment-methods-item-title">Pay by Ethereum</h2>
    
                                            {(chainID !== 1)?(<>
                                                <div className="metamask-chain-msg metamask-msg-error big-error">You should switch to Ethereum MainNet in your wallet!</div>
                                            </>) : (<>  

                                                <form onSubmit={handleSubmit}>
                                                    {(transactionState === 2 || transactionState === 4)?(<>
                                                        <div className="icb-container-input-disable">{tokenAmount}</div>
                                                    </>):(<>
                                                        <input type="number" value={tokenAmount} name="amount" maxLength="2" onChange={checkAmount} className="icb-container-input"/>
                                                    </>)}

                                                    <div className="icb-container-max-label">max.{maxAmount}</div>

                                                    {( (transactionState === 0 || transactionState === 1) && (tokenAmount >= 1 || tokenAmount <= maxAmount) )?(
                                                        <input type="submit"  value="Pay with ethereum" className="icb-container-button" />
                                                    ) : (
                                                        <input type="submit" value="Pay with ethereum" className="icb-container-button icb-container-button-disable"/>
                                                    )}
                                                </form>

                                                {(transactionState === 1) ? (<div className="metamask-msg-error">User denied transaction signature</div>) : ""}
                                                            
                                                {(transactionState === 2) ? (<div className="metamask-msg-success">Transaction has been successfully sent to the blockchain.<br />{tokenAmount} tokens gonna appear in your wallet soon!</div>) : ""}
                                                        
                                                {(transactionState === 3) ? (<div className="metamask-msg-error">You don't have enough Eth!</div>) : ""}
                                                        
                                                {(transactionState === 4) ? (<div className="metamask-msg-success">You need to CONFIRM the transaction in your wallet!</div>) : ""}

                                                {(minted) ? (<div className="metamask-msg-success">Successfully minted!</div>) : ""}

                                                <div className="icb-container-you-have" style={{marginBottom: "5px", fontSize: "1.2em"}}>
                                                    Total: <span>{(tokenAmount)?(tokenPrice*tokenAmount/convertRate):("0")} ETH</span>
                                                </div>

                                                <div className="icb-container-you-have" style={{marginBottom: "20px", fontSize: "0.9em"}}>
                                                    You have:  {((tokenPrice*tokenAmount/convertRate) > userBalance/convertRate)?(<span className="redBalance">{Math.floor(userBalance/convertRate * 1000) / 1000} ETH</span>):(<span>{Math.floor(userBalance/convertRate * 1000) / 1000} ETH</span>)}
                                                </div>

                                                <div className="token-payment-gas-price" style={{marginBottom: "25px"}}>
                                                    Current Gas Price: {(gasPrice < 140) ? (<span className="greenGas">{gasPrice} gwei</span>):((gasPrice > 250)? (<span className="redGas">{gasPrice} gwei</span>) : (<span>{gasPrice} gwei</span>) )}
                                                </div>

                                                <div className="icb-container-payment-description">
                                                    After clicking the "Pay" button, you will need to confirm the transaction in your cryptocurrency wallet. For example, in <a href="https://metamask.io" title="Metamask" target="_blank" rel="noreferrer nofollow noopener">Metamask</a> or <a href="https://walletconnect.com" title="WalletConnect" target="_blank" rel="noreferrer nofollow noopener">WalletConnect</a>.
                                                </div>
        
                                            </>)}
                                        </div>
                                    </>)}
                                    
                                    </>):("")}
                                                
                                    
                                        {(transactionState === 2 || transactionState === 4)?(<>
                                            <div className="token-payment-methods-item-disable">
                                                This payment method is not available while you are working with another payment method!
                                            </div>
                                        </>):(<>
                                            <div className="token-payment-methods-item">
                                                <h2 className="token-payment-methods-item-title">Pay with credit card</h2>
                                                
                                                <form onSubmit={handleSubmitPaper}>
                                                    {(paperTransactionState === 1 || paperTransactionState === 2)?(<>
                                                        <div className="icb-container-email-input-disable">{userEmail}</div>
                                                    </>):(<>
                                                        <input type="email" value={userEmail} name="email" placeholder="Enter your email" onChange={checkEmail} className="icb-container-email-input" />
                                                    </>)}
                                                    
                                                    {(!validEmail && userEmail !== "")?(<div className="icb-container-invalid-email-error">Invalid email</div>):("")}
                                                    
                                                    {(paperTransactionState === 0)?(<>
                                                        {(validEmail)?(
                                                            <input type="submit"  value="Pay with credit card" className="icb-container-button" />
                                                        ) : (
                                                            <input type="submit" value="Pay with credit card" className="icb-container-button icb-container-button-disable"/>
                                                        )}
                                                    </>):(<>
                                                        <input type="submit" value="Pay with credit card" className="icb-container-button icb-container-button-disable"/>
                                                    </>)}
                                                    
                                                    {(paperTransactionState === 1) && <div className="metamask-msg-normal">Waiting for response from merchant...</div>}

                                                    {(paperTransactionState === 2) && <div className="metamask-msg-success">Redirect to merchant...</div>}

                                                    {(isPaperError) && <div>Error: Merchant error</div>}

                                                    <div className="icb-container-payment-description">
                                                        After clicking the "Pay" button, you will be redirected to the <a href="https://paper.xyz" title="Paper.xyz" target="_blank" rel="noreferrer nofollow noopener">Paper.xyz</a>, where you can pay for your token with a credit card. Paper.xyz will transfer a token to your wallet after payment. 
                                                    </div>
                                                </form>
                                            </div>
                                        </>)}
                                </div>
                            </>)}

                            {(userAddress !== '0') ? (<div className="icb-container-buy-address">Your address: <span>{userAddress}</span></div>) : "" }

                            <div style={{marginBottom: "50px"}}>
                                If you need any help with minting please contact us:<br /><a href={LinkDs} title="Discord" target="_blank" rel="noreferrer nofollow noopener" style={{fontWeight:"500"}}>Discord</a>&nbsp; <a href={LinkTw} title="Twitter" target="_blank" rel="noreferrer nofollow noopener"style={{fontWeight:"500"}}>Twitter</a>
                            </div>               
                        </div>
                    </>):(<>
                        <h1>Chrysanthemum Public Mint</h1>
                        <h2>Sold out</h2>
                    </>)}
                </>):(<>
                    <h1>Chrysanthemum Public Mint</h1>
                    <h2>Sales haven't started yet</h2>
                    {/* <h2>Minting ended!</h2> */}
                </>)}
            </div>
        </>
    )
}

export default Mint2