import { useEffect } from 'react';
import { Helmet } from "react-helmet-async";

import DropsList from '../common/DropsList';
import NewArtists from '../common/NewArtists';

const Drops = ({AppName, AppUrl, setCurrentPage}) => {

    useEffect(() => {
		setCurrentPage('drops');
	}, [setCurrentPage]);

    return (
        <>
            <Helmet>
                <title>{'All Drops - ' + AppName}</title>
                <meta name="description" content={'All Drops - ' + AppName} />
                <meta name="keywords" content={'All Drops - ' + AppName} />
                <meta property="og:title" content={'All Drops - ' + AppName} />
                <meta property="og:url" content={AppUrl+'/drops'} />
                <meta property="og:description" content={'All Drops - ' + AppName} />
                <meta property="og:image" content={AppUrl + '/share.jpg'} />
                <link rel="canonical" href={AppUrl+'/drops'} />
            </Helmet>

            <section>
                <h1>All Drops</h1>
                <DropsList />
                <NewArtists />
            </section>
        </>
    )
}

export default Drops